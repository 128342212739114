@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }

  .btn-primary {
    @apply px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 
           transition-colors duration-200 focus:outline-none focus:ring-2 
           focus:ring-primary-500 focus:ring-offset-2;
  }

  .btn-secondary {
    @apply px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-md 
           hover:bg-gray-50 transition-colors duration-200 focus:outline-none 
           focus:ring-2 focus:ring-primary-500 focus:ring-offset-2;
  }

  .card {
    @apply bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200;
  }

  .input {
    @apply w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none 
           focus:ring-2 focus:ring-primary-500 focus:border-transparent;
  }
}